import * as React from 'react';
import '../testat.css';
import Login from './login';
import { Auth } from 'aws-amplify';
import Particles from 'react-tsparticles';
import { useEffect, useCallback } from 'react';
import awsConfig from './aws-exports.js';
import { navigateToUrl } from 'single-spa';

const Main: React.FC = () => {
  useEffect(() => {
    Auth.configure(awsConfig);
    checkCurrentUser();

    window.addEventListener('requestToken', checkCurrentUser);
    window.addEventListener('single-spa:before-routing-event', checkCurrentUser);
    window.addEventListener('requestLogout', handleSignOut);

    const expirationCheck = setInterval(() => {
      const expirationTime = localStorage.getItem('tokenExpiration');
      const currentTime = new Date().getTime();
      if (expirationTime && currentTime > parseInt(expirationTime)) {
        handleSignOut(); // Reutiliza la lógica de signOut para manejar la expiración
      }
    }, 60000); // Comprueba cada 60 segundos

    return () => {
      window.removeEventListener('single-spa:before-routing-event', checkCurrentUser);
      window.removeEventListener('requestLogout', handleSignOut);
      window.removeEventListener('requestToken', checkCurrentUser);
      clearInterval(expirationCheck);
    };
  }, []);

  const checkCurrentUser = useCallback(async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const token = currentUser.signInUserSession.accessToken.jwtToken;
      const userData = localStorage.getItem('sharedUserState');

      if (userData === null) {
        emitUserAuthenticatedEvent(currentUser);
      } else {
        window.dispatchEvent(new CustomEvent('userToken', { detail: { tokenValue: token } }));
      }
    } catch (error) {
      console.error('Error verifying user:', error);
      handleSignOut();
    }
  }, []);

  const emitUserAuthenticatedEvent = (user: any) => {
    const token = user.signInUserSession.accessToken.jwtToken;
    const expirationTime = user.signInUserSession.accessToken.payload.exp * 1000;

    window.dispatchEvent(new CustomEvent('userToken', { detail: { tokenValue: token } }));
    safeSetItem('tokenExpiration', expirationTime.toString());
    safeSetItem('sharedUserState', JSON.stringify(user));
  };

  const handleSignOut = useCallback(async () => {
    try {
      localStorage.clear();
      await Auth.signOut();
      navigateToUrl('/login');
    } catch (error) {
      console.error('Error signing out:', error);
      navigateToUrl('/login');
    }
  }, []);

  function safeSetItem(key: any, value: any) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      console.error('Error setting item in local storage: ', e);
      if (e instanceof DOMException && e.code === DOMException.QUOTA_EXCEEDED_ERR) {
        localStorage.clear();
        localStorage.setItem(key, value);
      }
    }
  }

  if (window.location.pathname != '/login') {
    return null;
  }

  return (
    <div className="main">
      <div className="d1" />
      <div className="d2" />
      <div className="d3" />
      <Login />
      <div className="d4" />
      <Particles
        options={{
          fullScreen: {
            enable: true,
            zIndex: 0
          },
          particles: {
            number: {
              value: 200,
              limit: 300,
              density: {
                enable: true,
                value_area: 800
              }
            },
            color: {
              value: '#ffffff'
            },
            shape: {
              type: 'circle',
              stroke: {
                width: 0,
                color: '#000000'
              },
              polygon: {
                nb_sides: 5
              },
              image: {
                src: 'images/github.svg',
                width: 100,
                height: 100
              }
            },
            opacity: {
              value: 0.5,
              random: true,
              anim: {
                enable: true,
                speed: 1,
                opacity_min: 0.5,
                sync: false
              }
            },
            size: {
              value: 20,
              random: true,
              anim: {
                enable: true,
                speed: 10,
                size_min: 10,
                sync: false
              }
            },
            line_linked: {
              enable: true,
              distance: 100,
              color: '#ffffff',
              opacity: 1,
              width: 1
            },
            move: {
              enable: true,
              speed: 2,
              direction: 'none',
              random: false,
              straight: false,
              out_mode: 'out',
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
              }
            }
          },
          interactivity: {
            detect_on: 'canvas',
            events: {
              onHover: {
                enable: false,
                mode: 'bubble',
                parallax: {
                  enable: false,
                  force: 60,
                  smooth: 10
                }
              },
              onClick: {
                enable: true,
                mode: 'push'
              },
              resize: true
            },
            modes: {
              grab: {
                distance: 400,
                lineLinked: {
                  opacity: 1
                }
              },
              bubble: {
                distance: 400,
                size: 100,
                duration: 2,
                opacity: 1
              },
              repulse: {
                distance: 200
              },
              push: {
                particles_nb: 4
              },
              remove: {
                particles_nb: 2
              }
            }
          },
          backgroundMask: {
            enable: true,
            cover: {
              color: {
                value: 'rgba(0, 0, 0)'
              },
              opacity: 50
            }
          },
          retina_detect: true,
          fps_limit: 60,
          background: {
            image:
              "url('https://images.unsplash.com/photo-1464822759023-fed622ff2c3b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80')"
          }
        }}
      />
    </div>
  );
};

export default Main;
