import { QueryClient, QueryClientProvider } from 'react-query';
import Main from './components/main';
import { UserProvider } from './components/user-context';
export default function Root(props) {
  const queryClient = new QueryClient();

  return (
    <>
      <UserProvider>
        <QueryClientProvider client={queryClient}>
          <Main />
        </QueryClientProvider>
      </UserProvider>
    </>
  );
}
