/* tslint:disable */
/* eslint-disable */
//auth
const awsConfig = {
  aws_appsync_graphqlEndpoint: process.env.APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.APP_AWS_APPSYNC_REGION,
  aws_appsync_authenticationType: process.env.APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
  region: process.env.APP_AWS_REGION,
  userPoolId: process.env.APP_AWS_USER_POOL_ID,
  userPoolWebClientId: process.env.APP_AWS_USER_POOL_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.APP_AWS_AUTH_DOMAIN,
    redirectSignIn: process.env.APP_AWS_AUTH_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.APP_AWS_AUTH_REDIRECT_SIGN_OUT,
    responseType: process.env.APP_AWS_AUTH_RESPONSE_TYPE,
    scope: [
      // 'phone',
      'email',
      // 'profile',
      'openid'
      // 'aws.cognito.signin.user.admin',
      // 'authentication_api/ValidateCredentials',
      // 'user-identity-api/ValidateOtp',
      // 'user-identity-api/ValidateUser'
    ]
  }
};
export default awsConfig;
/* tslint:disable */
/* eslint-disable */
