import * as React from 'react';
import '../testat.css';
import ImageDisplay from './image-display';
import AmplifyLogin from './amplify-approach';
// @ts-ignore

const Login: React.FC = () => {
  return (
    <div className='form-container'>
      <div className='inside-form-container'>
        <ImageDisplay imageName={'vulcanus.svg'} height={'80px'} width={'340px'} />
        <AmplifyLogin />
      </div>
    </div>
  );
};
export default Login;
