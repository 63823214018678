import * as React from 'react';

export const UserContext = React.createContext<UserContextValue>({
  user: null,
  setUser: (value) => {},
});

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = React.useState('');

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

interface UserContextValue {
  user: string | null;
  setUser: React.Dispatch<React.SetStateAction<string>>;
}


export interface LoggedUser {
  name: string;
  lastName: string;
}
