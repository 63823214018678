import * as React from 'react';

interface ImageDisplayProps {
  imageName: string;
  width: string;
  height: string;
}

const ImageDisplay: React.FC<ImageDisplayProps> = ({
                                                     imageName,
                                                     width,
                                                     height,
                                                   }) => {
  return (
    <div>
      <img
        src={require(`./images/${imageName}`)}
        alt={imageName}
        style={{ width, height }}
      />
    </div>
  );
};

export default ImageDisplay;
