import { VuButton } from '@avaldigitallabs/adl-rockstars-design-system-vulcanus';
import { Auth } from 'aws-amplify';
import * as React from 'react';
import awsConfig from './aws-exports.js';
import { navigateToUrl } from 'single-spa';

function AmplifyLogin() {
  Auth.configure(awsConfig);

  const handleSignIn = async () => {
    try {
      const identityProvider = process.env.FEDERATED_IDENTITY_PROVIDER || '';
      // Inicia sesión con el proveedor federado especificado.
      await Auth.federatedSignIn({ customProvider: identityProvider });
    } catch (error) {
      console.error('Error during sign in:', error);
    }
  };

  return (
    <div className={'w-full'}>
      <VuButton fullWidth={true} type={'button'} text={'Sign In'} onClick={handleSignIn} />
    </div>
  );
}

export default AmplifyLogin;
